.App {
  text-align: left;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.lineup-player, .injury-player, .previous-result  {
  border: 1px solid #ccc;
  border-width: 0 0 1px 0;
  padding: 5px;
}

.result-indicator-W {
  color: green;
  font-weight: bold;
}

.result-indicator-L {
  color: red;
  font-weight: bold;
}

.injury-description {
  display: none;
  font-size: 0.8em;
}

.injury-more-link {
  font-size: 0.8em;
}

.previous-result .opponent {font-weight: bold;}

.copy-to-clipboard {
  background: #c41141;
  color: white;
  border: none;
  padding: 5px;
  display: block;
  margin: 10px 0;
}

.odds th {
  text-transform: uppercase;
}

.odds th, .odds td, .odds {
  padding: 5px;
  border: 1px solid #ccc;
  border-collapse: collapse;
  text-align: center;
}
